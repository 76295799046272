import React from "react";
import dayjs from 'dayjs';

export const checkObjectKeys = (obj) => {
    if (Object.keys(obj).length === 0) return false
    let checkKeys = [];
    Object.keys(obj).forEach(key => {
        checkKeys.push(typeof obj[key] === "boolean" ? true : typeof obj[key] === 'object' ? !!Object.keys(obj[key]).length : typeof obj[key] === 'number' ? !isNaN(obj[key]) : !!obj[key])
    })
    return checkKeys.every(i => i === true)
};

export const checkObjectSomeKeys = (obj) => {
    if (Object.keys(obj).length === 0) return false
    let checkKeys = [];
    Object.keys(obj).forEach(key => {
        checkKeys.push(typeof obj[key] === "boolean" ? true : obj[key] !== null && typeof obj[key] === 'object' ? !!Object.keys(obj[key]).length : typeof obj[key] === 'number' ? !isNaN(obj[key]) : !!obj[key])
    })
    return checkKeys.some(i => i === true)
};

export const checkArrayObjectKeys = (arr) => {
    if (arr.length === 0) return false
    let checkObjs = [];
    arr.forEach(obj => {
        checkObjs.push(checkObjectKeys(obj))
    })
    return checkObjs.every(i => i === true)
};

export const rangePresets = [
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Последние 14 дней',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Последние 90 дней',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];


export const valueInput = (value) => {
    const list = `${value}`.split('.');
    return `${list[1] >= 0 ? `${list[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + `.${list[1]}` : `${list[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
};


export const numberFormatter = (value) => {
    // Sonni butun va kasr qismlariga ajratish
    let [integerPart, decimalPart = ''] = `${value}`.split('.');

    // Butun qismni bo‘sh joylar bilan formatlash
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Kasr qismini formatlash (2 xonagacha)
    if (decimalPart.length === 0) {
        decimalPart = '00'; // Agar kasr qismi bo‘lmasa, .00 qo‘shish
    } else if (decimalPart.length === 1) {
        decimalPart += '0'; // 1 ta bo‘lsa, 0 qo‘shish
    } else if (decimalPart.length > 2) {
        decimalPart = Math.round(+`0.${decimalPart}` * 100).toString().padStart(2, '0');
    }

    // Formatlangan natijani qaytarish
    return `${integerPart}.${decimalPart}`;
};


export const getOptionsArray = (data, key, arg = {}) => {
    if (!data?.length) return []
    const {label, type} = arg
    console.log("qwerty")
    return data?.map(i => {
        return {
        key: !type ? (Array.isArray(key) ? key.map(k => i[k]?.toString()).filter(f => f).join(" ") : i[key]) : i,
        value: !type ? (Array.isArray(key) ? key.map(k => i[k]?.toString()).filter(f => f).join(" ") : i[key]) : i,
        ...(label ? {label: label(i)} : {})
    }})
}

export const EditableContext = React.createContext(null);

export function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export function DownloadFile(uploadData) {
    let sampleArr = base64ToArrayBuffer(uploadData.byte);
    let blob = new Blob([sampleArr], {type: uploadData.type});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = uploadData.name;
    link.click();
}


const borders = {
    top: {style: "thin"},
    bottom: {style: "thin"},
    left: {style: "thin"},
    right: {style: "thin"}
}

export const excelDataSet = (data, columns, selectedColumns) => {

    let column = columns.filter(i => !selectedColumns.length ? i.key : i.key && selectedColumns.some(j => j === i.key));
    let setColumns = column.map(i => {
            return {title: i.title, style: {border: borders, font: {sz: "14", bold: true}}, width: {wpx: 150}}
        }
    );

    let sData = data.map(j => column.map(i => {
                if (i.key === "roles") {
                    return {
                        value: j[i.key] || j[i.key] === false ? j[i.key][0].authority === "ROLE_ADMIN" ? `АДМИН` : j[i.key][0].authority === "ROLE_SALESMAN" ? `ПРОДАВЕЦ` : j[i.key][0].authority === "ROLE_ENGINEER" ? `ИНЖЕНЕР` : j[i.key][0].authority === "ROLE_BUGALTER" ? `БУГАЛТЕР` : j[i.key][0].authority === "ROLE_DIRECTOR" ? `ДИРЕКТОР` : j[i.key][0].authority === "ROLE_DEPUTY_OF_DIRECTOR" ? `ЗАМЕСТИТЕЛЬ` : j[i.key][0].authority === "ROLE_HEAD_OF_DEPARTMENT" ? `ГЛАВА ОТДЕЛЕНИЯ` : j[i.key][0].authority === "ROLE_USER" ? `ПОЛЬЗОВАТЕЛЬ` : "" : "(пусто)",
                        style: {border: borders, font: {bold: false}}
                    }
                } else if (i.key === "storesId") {
                    return {
                        value: j[i.key] || j[i.key] === false ? JSON.stringify(j[i.key]).substring(1, JSON.stringify(j[i.key]).length - 1) : "(пусто)",
                        style: {border: borders, font: {bold: false}}
                    }
                } else {
                    return {
                        value: j[i.key] || j[i.key] === false ? j[i.key] : "(пусто)",
                        style: {border: borders, font: {bold: false}}
                    }
                }
            }
        )
    );

    return [
        {
            columns: setColumns,
            data: sData
        }
    ]
};

export function isJSON(str) {
    if (/^\s*$/.test(str)) return false;
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
    str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
    return (/^[\],:{}\s]*$/).test(str);
}

export function getTranslateState(t, state) {
    let newState = {};
    Object.keys(state).forEach(key => newState[key] = t(state[key]))
    return newState;
}

export function replaceAll(str, replaceList = {}) {
    let newStr = str;
    Object.keys(replaceList).forEach(key => newStr = newStr.replaceAll(new RegExp(`:${key}`, 'g'), replaceList[key]));
    return newStr;
}

export function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function getStatusText(code, t) {
    switch (code) {
        case 110:
            return t('consignorSent');
        case 120:
            return t('consignorCanceled');
        case 130:
            return t('responsiblePersonRejected');
        case 140:
            return t('responsiblePersonAccepted');
        case 150:
            return t('responsiblePersonTillReturned');
        case 160:
            return t('responsiblePersonGiven');
        case 170:
            return t('consigneeRejected');
        case 180:
            return t('consigneeAccepted');
        case 190:
            return t('responsiblePersonReturned');
        case 200:
            return t('consignorReturnAccepted');
        case 998:
            return t('revoked');
        case 999:
            return t('taxCommitteeRevoked');
        default:
            return t("Unknown status");
    }
}